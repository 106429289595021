import { styled } from '@mui/material';
import React from 'react';

const titlePrincipal = '#3C4858';
const firstTitle = '#564b4b';
const secondTitle = '#a76f03';
const btnColor = '#f48c13';
const colorTitles = '#3C4858'


// #3C4858

// Limpiar el texto eliminando etiquetas HTML y entidades
const cleanParagraph = (string) => {
  const withoutTags = string.replace(/<\/?[^>]+(>|$)/g, "");
  const cleanString = withoutTags.replace(/&nbsp;/g, " ");
  return cleanString;
};

// Estilos para la sección principal
const SectionProducts = styled('section')({
  textAlign: 'center',
  fontFamily: 'Museo Sans',
  padding: '0 80px',
  color: colorTitles,
  '@media (max-width: 1024px)': {
    padding: '10px 20px',
  },
  '@media (max-width: 768px)': {
    padding: '10px 30px',
  },
});

// Estilos para el contenedor de títulos
const TitleProduct = styled('div')({
  margin: '0',
  fontWeight: 'bolder',
  textAlign: 'start',
  '& h2:nth-child(1)': {
    // color: firstTitle,
    margin: '0',
    fontWeight: 'bolder',
    fontSize: '3.4rem',
  },
  '& h2:nth-child(2)': {
    color: secondTitle,
    margin: '0',
    fontWeight: 'bolder',
    fontSize: '4rem',
    marginLeft: '50px',
  },
  '@media (max-width: 1600px)': {
    '& h2:nth-child(1)': {
      fontSize: '2.5rem',
      marginLeft: '0',
      lineHeight: '65px',
    },
    '& h2:nth-child(2)': {
      fontSize: '3rem',
      marginLeft: '0',
    }
  },
  '@media (max-width: 1024px)': {
    '& h2:nth-child(1)': {
      fontSize: '2.8rem',
      marginLeft: '0',
    },
    '& h2:nth-child(2)': {
      fontSize: '3.2rem',
      marginLeft: '0',

    }
  },
  '@media (max-width: 990px)': {
    '& h2:nth-child(1)': {
      marginTop: 10,
      fontSize: '2rem',
      marginLeft: '0',
      textAlign: 'left',
      lineHeight: '40px'
    },
    '& h2:nth-child(2)': {
      fontSize: '2.2rem',
      textAlign: 'left',
      lineHeight: '40px'
    }
  },
});



// Estilos para la imagen del producto
const ImageProduct = styled('img')({
  width: '70%',
  '@media (max-width: 1025px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    height: 'auto',
  },
});

// Estilos para el contenedor principal de los elementos
const ArticleContainer = styled('article')(({ invertOrder }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '150px',
  //marginTop: '50px',
  justifyContent: 'center',
  alignItems: 'center',
  gridTemplateAreas: invertOrder ? '"title img"' : '"img title"',  // Cambia el orden si invertOrder es true
  '@media (max-width: 1440px)': {
    gap: '80px',
  },
  '@media (max-width: 1024px)': {
    gap: '50px',
  },
  '@media (max-width: 990px)': {
    marginTop: '20px',
    gridTemplateColumns: '1fr',
    gridTemplateAreas: '"img" "title" ', // En móviles, siempre apilar (primero título, luego imagen)
    gap: '0',
  },
}));

// Contenedor para la fila de los párrafos, con orden condicional
const ParagraphContainer = styled('div')(({ invertOrder }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: '150px',
  gridTemplateAreas: invertOrder ? '"paragraph2 paragraph1"' : '"paragraph1 paragraph2"',  // Cambia el orden si invertOrder es true
  '@media (max-width: 1440px)': {
    gap: '80px',
  },
  '@media (max-width: 1024px)': {
    gap: '50px',
  },
  '@media (max-width: 990px)': {
    gridTemplateColumns: '1fr',
    gridTemplateAreas: '"paragraph1" "paragraph2"', // En móviles, siempre apilar
    gap: 0,
  },
}));

const TextBlock = styled('div')({
  // borderBottom: '1px solid black',
  h3: {
    textAlign: 'start',
    // color: firstTitle, 
    fontWeight: '600',
    '@media (max-width: 768px)': {
      fontSize: '1.3rem'
    }
  },
  p: {
    textAlign: 'justify',
    marginBottom: '20px',
    color: '#999'
  },
  '@media (max-width: 1600px)': {
    h3: {
      fontSize: '1.3rem',
    },
    p: {
      fontSize: '14px'
    }
  },
  '@media (max-width: 1024px)': {
    h3: {
      fontSize: '1.3rem',
    },
  },
});

const TitlePrincipal = styled('h2')({
  color: titlePrincipal,
  fontSize: '2.6rem',
  fontWeight: '900',
  '@media (max-width: 768px)': {
    fontSize: '2rem',
  }
})

// Estilos para el botón
const BtnConoceMas = styled('button')({
  color: 'white',
  backgroundColor: btnColor,
  padding: '10px 100px',
  borderRadius: '30px',
  border: 'none',
  // marginTop: '20px',
  fontSize: '1.5rem',
  cursor: 'pointer',
  transition: '0.5s',
  '&:hover': {
    backgroundColor: '#bf6701',
  },
  '@media (max-width: 1600px)': {
    fontSize: '0.8rem',
  },
  '@media (max-width: 1024px)': {
    fontSize: '1rem',
  },
  '@media (max-width: 768px)': {
    padding: '10px 20px',
    fontSize: '1rem',
  },

});

export const Products = ({ arrayProducts }) => {
  return (
    <SectionProducts>
      <TitlePrincipal>
        Nuestros Productos
      </TitlePrincipal>
      {arrayProducts.map((item, index) => {
        const { imagen, titulo1, titulo2, textos, posicion_imagen, url_boton } = item;
        return (
          <React.Fragment key={index} style={{ borderBottom: '1px solid black' }}>
            {/* Contenedor de dos columnas (imagen y títulos), con orden condicional */}
            <ArticleContainer invertOrder={!posicion_imagen}>
              {/* Imagen */}
              <div style={{ gridArea: 'img' }}>
                <ImageProduct alt={`imagen referente a ${titulo1}`} src={imagen} />
              </div>
              {/* Títulos */}
              <TitleProduct style={{ gridArea: 'title' }}>
                <h2>{titulo1}</h2>
                <h2>{titulo2}</h2>
              </TitleProduct>
            </ArticleContainer>
            {/* Párrafos en dos columnas, con orden condicional */}
            <ParagraphContainer invertOrder={!posicion_imagen}>
              {textos.map((paragraph, i) => {
                const { titulo, parrafo, url } = paragraph;
                return (
                  <TextBlock key={i} style={{ gridArea: i === 0 ? 'paragraph1' : 'paragraph2' }}>
                    <h3>
                      {titulo}
                      {/* {titulo + ' este es un titulo de prueba'} */}
                    </h3>
                    <p >
                      {cleanParagraph(parrafo)}
                      {url && (
                        <a href={url} style={{ color: '#f48c13', marginLeft: '10px' }}>Ver más...</a>
                      )}
                    </p>
                  </TextBlock>
                );
              })}
            </ParagraphContainer>

            {/* Botón para más productos */}
            {url_boton && (
              <BtnConoceMas onClick={() => window.location.assign(url_boton)}>
                Conoce más productos relacionados
              </BtnConoceMas>
            )}
            <div style={{ width: '100%', height: '1px', borderBottom: '1px solid rgb(97 97 97)', margin: '20px auto 20px' }} />
          </React.Fragment>
        );
      })}
    </SectionProducts>
  );
};


